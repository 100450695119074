<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
        >
          <div class="form-group">
            <label>Search String</label>
            <b-input
              v-model="TableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </div>
        </b-col>
        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            @click="showAddForm"
          >
            Add User
          </b-button>
        </b-col>

      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="TableSettings.perPage"
        :current-page="TableSettings.currentPage"
        :items="myProvider"
        :fields="TableSettings.fields"
        :sort-by.sync="TableSettings.sortBy"
        :sort-desc.sync="TableSettings.sortDesc"
        :sort-direction="TableSettings.sortDirection"
        :filter="TableSettings.filter"
        :filter-included-fields="TableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ TableSettings.currentPage * TableSettings.perPage - TableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active === 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              style="margin-right:5px;"
              size="sm"
              @click="showEditForm(row.item)"
            >
              Edit

            </b-button>

            <b-button
              size="sm"
              @click="showDistributionForm(row.item)"
            >
              area
            </b-button>

          </div>

        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="TableSettings.perPage"
              :options="TableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="TableSettings.currentPage"
            :total-rows="TableSettings.totalRows"
            :per-page="TableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-user-form"
      scrollable
      no-close-on-backdrop
      :title="modalTitle"
      @ok="handleOk"
    >
      <ValidationObserver
        ref="form"
      >
        <form
          role="form"
          novalidate
        >
          <ValidationProvider
            #default="{ errors }"
            name="email"
            vid="email"
            rules="required|email|max:100"
          >
            <div class="form-group">
              <label for="email">Email</label>
              <b-input
                id="email"
                v-model="user.email"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="enter email"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="first name"
            vid="first_name"
            rules="required|max:50"
          >
            <div class="form-group">
              <label for="first_name">First Name</label>
              <b-input
                id="first_name"
                v-model="user.first_name"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="enter first name"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="middle name"
            vid="middle_name"
            rules="max:50"
          >
            <div class="form-group">
              <label for="middle_name">Middle Name</label>
              <b-input
                id="middle_name"
                v-model="user.middle_name"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="enter middle name"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="last name"
            vid="last_name"
            rules="required|max:50"
          >
            <div class="form-group">
              <label for="last_name">Last Name</label>
              <b-input
                id="last_name"
                v-model="user.last_name"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="enter last name"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="user name"
            vid="username"
            rules="required"
          >
            <div class="form-group">
              <label for="username">User Name</label>
              <b-input
                id="username"
                v-model="user.username"
                type="text"
                :state="errors.length > 0 ? false : null"
                placeholder="enter user name"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="password"
            vid="password"
            :rules="passwordRule"
          >
            <div class="form-group">
              <label for="password">Password <span v-if="editMode">(Leave blank when not changing password)</span></label>
              <b-input
                id="password"
                v-model="user.password"
                type="password"
                :state="errors.length > 0 ? false : null"
                placeholder="enter password"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="password confirmation"
            vid="password_confirmation"
            :rules="passwordConfirmationRule"
          >
            <div class="form-group">
              <label for="password_confirmation">Password Confirmation <span v-if="editMode">(Leave blank when not changing password)</span></label>
              <b-input
                id="password_confirmation"
                v-model="user.password_confirmation"
                type="password"
                :state="errors.length > 0 ? false : null"
                placeholder="enter password confirmation"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="role"
            vid="role"
            rules="required"
          >
            <div class="form-group">
              <label for="role">Role</label>
              <b-form-select
                id="role"
                v-model="user.role"
                :options="roles"
                type="text"
                :state="errors.length > 0 ? false : null"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please select role --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="salesperson"
            vid="salesperson"
            rules=""
          >
            <div class="form-group">
              <label for="salesperson">Sales Person</label>
              <b-form-select
                id="salesperson"
                v-model="user.sale_people_id"
                :options="salespersons"
                type="text"
                :state="errors.length > 0 ? false : null"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please select sales person --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <div
            v-if="editMode && user.id !== 1"
            class="form-group"
          >
            <b-form-checkbox
              id="is_active"
              v-model="user.active"
              name="is_active"
              value="1"
              unchecked-value="0"
              :disabled="isBusy"
            >
              Is Active?
            </b-form-checkbox>
          </div>

        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          :disabled="isBusy"
          @click="ok()"
        >
          {{ editMode ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="isBusy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>

    </b-modal>

    <b-modal
      id="modal-distribution-form"
      size="lg"
      scrollable
      no-close-on-backdrop
      title="Assign Distribution"
    >

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettingDistribution.perPage"
        :current-page="tableSettingDistribution.currentPage"
        :sort-by.sync="tableSettingDistribution.sortBy"
        :sort-desc.sync="tableSettingDistribution.sortDesc"
        :sort-direction="tableSettingDistribution.sortDirection"
        :items="list_distribution"
        :fields="tableSettingDistribution.fields"
      >

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-form-checkbox
              v-model="selected"
              size="lg"
              :value="row.item.id"
              @change="checkDistributions(row.item)"
            />
          </div>

        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettingDistribution.perPage"
              :options="tableSettingDistribution.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettingDistribution.currentPage"
            :total-rows="tableSettingDistribution.totalRows"
            :per-page="tableSettingDistribution.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
      <template #modal-footer="{close}">
        <b-button
          variant="danger"
          :disabled="isBusy"
          @click="close()"
        >
          Close
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { AxiosError, AdminUserService, SharedListService, AdminUserDistributionService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminUsers',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Users'
    }
  },

  mixins: [formatter],

  data () {
    return {
      editMode: false,
      assignArea: false,
      selected: [],
      isBusy: false,
      roles: [
        { text: 'Administrator', value: 'admin' },
        { text: 'Warehouse', value: 'warehouse' },
        { text: 'Logistics', value: 'logistics' },
        { text: 'Manager', value: 'manager' },
        { text: 'Top Management', value: 'tm' },
        { text: 'End-User', value: 'user' },
        { text: 'Sales Receivable Accountant', value: 'sra' },
        { text: 'Credit Collection Officer', value: 'cco' }

      ],
      salespersons: [],
      user: {
        id: '',
        active: 1,
        email: '',
        username: '',
        password: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        role: null,
        sale_people_id: null
      },
      TableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'email', sortable: true },
          { key: 'first_name', sortable: true },
          { key: 'last_name', sortable: true },
          { key: 'role', sortable: true },
          { key: 'active', label: 'IS ACTIVE?' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      },
      tableSettingDistribution: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        fields: [
          { key: 'id', sortable: true },
          { key: 'distribution_code', sortable: true },
          { key: 'distribution_name', sortable: true },
          'actions'
        ]
      },
      list_distribution: [],
      modalShow: false

    }
  },

  computed: {
    modalTitle () {
      return this.editMode ? 'Edit User' : 'Add User'
    },

    passwordRule () {
      return `${this.editMode ? '' : 'required|'}min:8|confirmed:password_confirmation`
    },

    passwordConfirmationRule () {
      return `${this.editMode ? '' : 'required'}`
    }

  },

  mounted () {
    core.index()
    this.fetchDistributions()
    this.fetchSalesPerson()
  },

  methods: {

    async fetchSalesPerson () {
      this.salespersons = []
      await SharedListService.getSalePersonList().then(({ data }) => {
        data.forEach(item => {
          this.salespersons.push({
            value: item.id,
            text: item.person_name
          })
        })
      })
    },

    async checkDistributions (item) {
      const distribution = {
        distribution_id: item.id,
        user_id: this.user.id
      }

      await AdminUserDistributionService.post(distribution).then(response => {
        this.$swal.fire({
          icon: 'info',
          title: 'Info',
          text: response.data.message,
          confirmButtonColor: '#06C270',
          confirmButtonText: 'Dismiss'
        }).then(() => {
          this.$refs.table.refresh()
        })
      }).catch(error => {
        if (error instanceof AxiosError) {
          if (error.code === 422) {
            this.$refs.form.setErrors(error.message)
          }
        }
        this.isBusy = false
      })
    },

    async showDistributionForm (item) {
      this.user.id = item.id
      await AdminUserDistributionService.get(item.id).then(({ data }) => {
        const res = data?.map(el => {
          if (el.checked) {
            return el.id
          }
        })
        this.selected = res
        this.$bvModal.show('modal-distribution-form')
      })
    },

    async fetchDistributions () {
      this.list_distribution = []
      await SharedListService.getDistributionList().then(({ data }) => {
        this.list_distribution = data
      })
    },

    showAddForm () {
      this.editMode = false
      this.user.id = 0
      this.user.active = true
      this.user.email = ''
      this.user.password = ''
      this.user.password_confirmation = ''
      this.user.first_name = ''
      this.user.last_name = ''
      this.user.middle_name = ''
      this.user.username = ''
      this.user.sale_people_id = null
      this.user.role = null
      this.$bvModal.show('modal-user-form')
    },

    showEditForm (item) {
      this.editMode = true
      this.user.id = item.id
      this.user.active = item.active
      this.user.email = item.email
      this.user.password = ''
      this.user.password_confirmation = ''
      this.user.first_name = item.first_name
      this.user.last_name = item.last_name
      this.user.middle_name = item.middle_name
      this.user.username = item.username
      this.user.sale_people_id = item.sale_people_id
      this.user.role = item.role
      this.$bvModal.show('modal-user-form')
    },

    handleOk (bvModalEvt) {
      bvModalEvt.preventDefault()

      if (this.editMode) {
        this.confirmPutSubmit()
      } else {
        this.confirmStoreSubmit()
      }
    },

    async myProvider (ctx) {
      try {
        const { data } = await AdminUserService.get(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}`)
        this.TableSettings.totalRows = data.total_rows

        return data.data
      } catch {
        return []
      }
    },

    async confirmStoreSubmit () {
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.$bvModal.msgBoxConfirm('Do you really want to create user?', {
            title: 'Confirm Action',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'Yes',
            cancelVariant: 'danger',
            cancelTitle: 'No'
          })
            .then(value => {
              if (value) {
                this.onStoreSubmit()
              }
            })
        }
      })
    },

    async onStoreSubmit () {
      this.isBusy = true
      await AdminUserService.post(this.user).then(response => {
        this.$bvModal.hide('modal-user-form')
        this.isBusy = false
        this.$swal.fire({
          icon: 'info',
          title: 'Info',
          text: response.data.message,
          confirmButtonColor: '#06C270',
          confirmButtonText: 'Dismiss'
        }).then(() => {
          this.$refs.table.refresh()
        })
      }).catch(error => {
        if (error instanceof AxiosError) {
          if (error.code === 422) {
            this.$refs.form.setErrors(error.message)
          }
        }
        this.isBusy = false
      })
    },

    async confirmPutSubmit () {
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.$bvModal.msgBoxConfirm('Do you really want to save any changes you made?', {
            title: 'Confirm Action',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'Yes',
            cancelVariant: 'danger',
            cancelTitle: 'No'
          })
            .then(value => {
              if (value) {
                this.onPutSubmit()
              }
            })
        }
      })
    },

    async onPutSubmit () {
      this.isBusy = true
      await AdminUserService.put(this.user).then(response => {
        this.$bvModal.hide('modal-user-form')
        this.isBusy = false
        this.$swal.fire({
          icon: 'info',
          title: 'Info',
          text: response.data.message,
          confirmButtonColor: '#06C270',
          confirmButtonText: 'Dismiss'
        }).then(() => {
          this.$refs.table.refresh()
        })
      }).catch(error => {
        if (error instanceof AxiosError) {
          if (error.code === 422) {
            this.$refs.form.setErrors(error.message)
          }
        }
        this.isBusy = false
      })
    }
  }
}
</script>
